// /* boot.js */
import {bootstrap, container} from './include/services.js';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // Import the default styles
// Optionally import a theme:
// import 'tippy.js/themes/light.css'; // Or 'dark', 'google', etc.
// Or import the border rounder theme
// import 'tippy.js/themes/border.css';

// import { followCursor } from '@tippyjs/plugin-follow-cursor';
console.log('Tippy is ready!');

/** @type {import('./include/services/TemplateCollection.js').TemplateCollection} */
const templateCollection = container.resolve('templateCollection');
templateCollection.add('modal', `
    <div class="modal fade" id="<%id%>" tabindex="-1" role="dialog" aria-labelledby="<%id%>-title">
        <div class="modal-dialog <%size%>" role="document">
            <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h4 class="modal-title" data-modal-section="title" id="<%id%>-title"><%title%></h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" data-modal-section="body" id="<%id%>-body"><%body%></div>
                <div class="modal-footer" data-modal-section="footer" id="<%id%>-footer">
                    <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
`);

async function fetchTooltipContent(url) {
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        const content = await response.text(); // or .json() if the response is JSON
        return content;
    } catch (error) {
        console.error('Error fetching tooltip content:', error);
        return 'Failed to load content'; // Fallback message
    }
}

/** @type {import('./include/services/DOMChangeObserver.js').DOMChangeObserver} */
const observer = container.resolve('observer');

observer.attachCallback(function(node) {
    let tooltipButtons = node.querySelectorAll('[data-tippy="url"][data-tippy-event][data-tippy-source][data-tippy-content]');

    tooltipButtons.forEach((button) => {
        const source = button.getAttribute('data-tippy-source');
        button.addEventListener('mouseenter', async () => {
            if (button.getAttribute('data-tippy-event') === 'hover') {
                const content = await fetchTooltipContent(url);
                button.setAttribute('data-tippy-content', content);
                tippy(button, {
                    content: content,
                    allowHTML: true,
                    trigger: 'mouseenter',
                    // interactive: true,
                    theme: 'light',
                    // plugins: [followCursor, inlinePositioning], // Add plugins here
                    onShow(instance) {
                        // Update content every time the tooltip is shown
                        fetchTooltipContent(source).then(fetchedContent => {
                            instance.setContent(fetchedContent);
                        });
                    },
                }).show();
            }
        });

        button.addEventListener('click', async () => {
            if (button.getAttribute('data-tippy-event') === 'click') {
                const content = await fetchTooltipContent(source);
                button.setAttribute('data-tippy-content', content);
                tippy(button, {
                    content: content,
                    allowHTML: true,
                    trigger: 'click',
                    interactive: true,
                    theme: 'light',
                    // plugins: [followCursor, inlinePositioning], // Add plugins here
                    onShow(instance) {
                        // Update content every time the tooltip is shown
                        fetchTooltipContent(source).then(fetchedContent => {
                            instance.setContent(fetchedContent);
                        });
                    },
                }).show();
            }
        });
    });

    tooltipButtons = node.querySelectorAll('[data-tippy="page"][data-tippy-event][data-tippy-source][data-tippy-content]');
    tooltipButtons.forEach((button) => {
        let content = button.getAttribute('data-tippy-content');

        /** @type {String} */
        const source = button.getAttribute('data-tippy-source');
        /** @type {HTMLElement} */
        const element = document.querySelector(source);
        if (element) {
            element.classList.add('d-none');
            content += element.innerHTML;
        }

        button.addEventListener('mouseenter', () => {
            if (button.getAttribute('data-tippy-event') === 'hover') {
                tippy(button, {
                    content: content,
                    allowHTML: true,
                    trigger: 'mouseenter',
                    // interactive: true,
                    theme: 'light',
                    // plugins: [followCursor, inlinePositioning], // Add plugins here
                }).show();
            }
        });
        button.addEventListener('click', () => {
            if (button.getAttribute('data-tippy-event') === 'click') {
                tippy(button, {
                    content: content,
                    allowHTML: true,
                    interactive: true,
                    trigger: 'click',
                    theme: 'light',
                    // plugins: [followCursor, inlinePositioning], // Add plugins here
                }).show();
            }
        });
    });
}, true);

window.bootstrap = bootstrap;
window.container = container;
